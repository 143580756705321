<template>
  <!-- login-area -->
  <section class="login-area">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 col-lg-7">
          <div class="login-left row">
            <div class="introduction">
              <div
                class="heading wow fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay=".5s"
              >
                <h1>Welcome To DealersGear CRM</h1>
                <p>The Platform Built By Dealers For Dealers</p>
              </div>
              <div
                class="heading-img wow fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay="1s"
              >
                <img
                  class="img-fluid"
                  src="../assets/img/Frame.svg"
                  alt="image"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-5">
          <div class="login-right row">
            <div class="login-form custom-form">
              <!-- logo -->
              <div
                class="logo-login wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay="1s"
              >
                <a href="index.html"
                  ><img
                    class="img-fulid"
                    src="../assets/img/logo.svg"
                    alt="logo"
                /></a>
              </div>
              <!-- logo -->
              <div
                class="login-title wow fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay="1s"
              >
                <h2>Log In To Your Account</h2>
              </div>
              <p v-if="$route.query.message" class="alert alert-success">
                {{ message }}
              </p>
              <p></p>
              <form
                class="wow fadeInRight login_"
                data-wow-duration="1s"
                data-wow-delay="1s"
                v-on:submit.prevent="loginProcess"
              >
                <div class="login-error-message text-center" v-if="authError">
                  Invalid Email or Password
                </div>
                <div
                  class="form-group label-design"
                  v-on:click="inputLabelStyle($event)"
                >
                  <label
                    class="label-class label-top"
                    for="inlineFormInputGroup"
                    >Email Address</label
                  >
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <img
                          class="img-fluid"
                          src="../assets/img/Account.svg"
                          alt=""
                        />
                      </div>
                    </div>
                    <input
                      type="email"
                      class="form-control"
                      @focus="inputLabelStyle($event, 'parent')"
                      id="inlineFormInputGroup"
                      placeholder=""
                      v-model="login_.email"
                    />
                  </div>
                </div>
                <div
                  class="validate-error-message"
                  v-if="$vd.login_.email.$hasError === true"
                >
                  {{ $vd.login_.$errors.email[0] }}
                </div>
                <div
                  class="form-group label-design"
                  v-on:click="inputLabelStyle($event)"
                >
                  <label
                    class="label-class label-top"
                    for="exampleInputPassword1"
                    >Password</label
                  >
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <img
                          class="img-fluid"
                          src="../assets/img/lock.svg"
                          alt=""
                        />
                      </div>
                    </div>
                    <input
                      v-bind:type="pType"
                      @focus="inputLabelStyle($event, 'parent')"
                      class="form-control"
                      id="exampleInputPassword1"
                      placeholder=""
                      v-model="login_.password"
                    />
                    <div class="input-group-text show-passs">
                      <img
                        class="img-fluid show-eye"
                        src="../assets/img/Eye.svg"
                        alt=""
                        v-on:click="showPass()"
                      />
                      <img
                        class="img-fluid hide-eye"
                        src="../assets/img/eyeclose.svg"
                        alt=""
                        v-on:click="hidePass()"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="validate-error-message"
                  v-if="$vd.login_.password.$hasError === true"
                >
                  {{ $vd.login_.$errors.password[0] }}
                </div>
                <div class="form-group form-check">
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      @click="remeberme($event)"
                      class="custom-control-input"
                      id="customCheckBox1"
                    />
                    <label class="custom-control-label" for="customCheckBox1"
                      >Remember Me</label
                    >
                    <!--<a class="forgot" href="#">Forgot password</a>-->
                    <router-link class="forgot" :to="{ path: 'forgot' }"
                      >Forgot Password</router-link
                    >
                  </div>
                </div>

                <button type="submit" class="login-btn" :disabled="btnLoader">
                  <span v-if="btnLoader"
                    ><span class="spinner-grow spinner-grow-sm"></span>
                    Loading...</span
                  >
                  <span v-if="!btnLoader">Log In</span>
                </button>
                <!-- <p class="allready">You don’t have a crm? <a href="#">Signup</a></p> -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- login-area -->
</template>

<script>
import $ from "jquery";
import VueDaval from "vue-daval";
import axios from "axios";
import firebase from "firebase";
export default {
  name: "Login",
  beforeCreate: function () {
    var auth = this.$storage.get("auth");
    if (auth) {
      this.$router.push("/dashboard");
    }
  },
  mixins: [VueDaval],
  data() {
    return {
      login_: { email: "", password: "" },
      pType: "password",
      authError: false,
      btnLoader: false,
      message: "",
    };
  },
  vdRules: {
    login_: {
      email: { required: true, type: "email" },
      password: { required: true },
    },
  },
  created() {
    setTimeout(() => {
      if ($("#inlineFormInputGroup").is(":-webkit-autofill")) {
        $(".label-design").addClass("input-border");
        $(".label-design label").addClass("label-top");
      }
    }, 1000);
  },
  methods: {
    subscribeTokenToTopic(token, topic) {
      fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`, {
        method: "POST",
        headers: new Headers({
          Authorization: `key=AAAAG69RFgo:APA91bFImVgPFU7wMbLfrI4cUU_TPFlkJQgn4MLrKxbAaEHMH-LO0HwECVaFvr1ApLXb7-3CNaGaQetr0fQ6_W7YOwAzwL8-DFCFYf6oxTf8hmEVZ16jmPN2KzrZ5eyKG3IB_zfnmpyL`,
        }),
      })
        .then((response) => {
          if (response.status < 200 || response.status >= 400) {
            console.log(response.status, response);
          }

          console.log(`"${topic}" is subscribed`);
        })
        .catch((error) => {
          console.error(error.result);
        });
      return true;
    },
    unsubscribeTokenToTopic(token, topic) {
      fetch(`https://iid.googleapis.com/iid/v1:batchRemove`, {
        method: "POST",
        headers: new Headers({
          Authorization: `key=AAAAG69RFgo:APA91bFImVgPFU7wMbLfrI4cUU_TPFlkJQgn4MLrKxbAaEHMH-LO0HwECVaFvr1ApLXb7-3CNaGaQetr0fQ6_W7YOwAzwL8-DFCFYf6oxTf8hmEVZ16jmPN2KzrZ5eyKG3IB_zfnmpyL`,
        }),
        body: JSON.stringify({
          to: "/topics/" + topic,
          registration_tokens: [token],
        }),
      })
        .then((response) => {
          if (response.status < 200 || response.status >= 400) {
            console.log(response.status, response);
          }

          console.log(`"${topic}" is unsubscribed`);
        })
        .catch((error) => {
          console.error(error.result);
        });
      return true;
    },
    remeberme(event) {
      if (event.target.checked) {
        localStorage.setItem("email", this.login_.email);
        localStorage.setItem("password", this.login_.password);
      } else {
        localStorage.removeItem("email");
        localStorage.removeItem("password");
        this.login_.email = "";
        this.login_.password = "";
      }
    },
    getRemeberCredientials() {
      if (localStorage.email && localStorage.password) {
        this.login_.email = localStorage.email;
        this.login_.password = localStorage.password;
        $("#customCheckBox1").prop("checked", true);
      }
    },
    loginProcess() {
      this.$vd.login_
        .$validate()
        .then(() => {
          this.btnLoader = true;
          axios
            .post("login", this.login_)
            .then((res) => {
              this.authError = false;
              if (res.data.success) {
                this.$storage.set("auth", res.data.success);
                axios.defaults.headers.common["Authorization"] =
                  res.data.success.token;
                if (res.data.success.user.user_type == 0) {
                  this.$router.push("/admin/dealers");
                } else {
                  let v = this;
                  this.$router.push("/dashboard");
                  const messaging = firebase.messaging();
                  messaging
                    .requestPermission()
                    .then(function () {
                      return messaging.getToken();
                    })
                    .then(function (token) {
                      console.log(token);
                    
                      if (token) {  
                        if (res.data.noti) {
                          if (res.data.noti.facebook == 1) {
                            v.subscribeTokenToTopic(
                              token,
                              "facebook" +
                                "_" +
                                v.$storage.get("auth").user.id +
                                "_" +
                                v.$storage.get("auth").user.dealer_id
                            );
                          }
                          if (res.data.noti.sms == 1) {
                            v.subscribeTokenToTopic(
                              token,
                              "sms" +
                                "_" +
                                v.$storage.get("auth").user.id +
                                "_" +
                                v.$storage.get("auth").user.dealer_id
                            );
                          }
                          if (res.data.noti.email == 1) {
                            v.subscribeTokenToTopic(
                              token,
                              "email" +
                                "_" +
                                v.$storage.get("auth").user.id +
                                "_" +
                                v.$storage.get("auth").user.dealer_id
                            );
                          }
                          if (res.data.noti.NewLead == 1) {
                            v.subscribeTokenToTopic(
                              token,
                              "NewLead" +
                                "_" +
                                v.$storage.get("auth").user.id +
                                "_" +
                                v.$storage.get("auth").user.dealer_id
                            );
                          }
                          if (res.data.noti.alert == 1) {
                            v.subscribeTokenToTopic(
                              token,
                              "alert" +
                                "_" +
                                v.$storage.get("auth").user.id +
                                "_" +
                                v.$storage.get("auth").user.dealer_id
                            );
                          }
                          if (res.data.noti.automatedtasks == 1) {
                            v.subscribeTokenToTopic(
                              token,
                              "automatedtasks" +
                                "_" +
                                v.$storage.get("auth").user.id +
                                "_" +
                                v.$storage.get("auth").user.dealer_id
                            );
                          }
                          if (res.data.noti.leadassign == 1) {
                            v.subscribeTokenToTopic(
                              token,
                              "leadassign" +
                                "_" +
                                v.$storage.get("auth").user.id +
                                "_" +
                                v.$storage.get("auth").user.dealer_id
                            );
                          }
                          if (res.data.noti.innercircle == 1) {
                            v.subscribeTokenToTopic(
                              token,
                              "innercircle" +
                                "_" +
                                v.$storage.get("auth").user.id +
                                "_" +
                                v.$storage.get("auth").user.dealer_id
                            );
                          }
                        }
                      }
                    })
                    .catch(function (err) {
                      console.log("User Chat Token Error" + err);
                    });

                
                }
                //this.$router.push('/dashboard');
              } else {
                this.btnLoader = false;
              }
            })
            .catch((err) => {
              this.authError = true;
              this.btnLoader = false;
              //setTimeout(() => this.authError=false, 5000);
              console.log(err);
            });
        })
        .catch(() => {});
    },
    showPass() {
      this.pType = "text";
      $(".show-passs .hide-eye").show();
      $(".show-passs .show-eye").hide();
    },
    hidePass() {
      this.pType = "password";
      $(".show-passs .hide-eye").hide();
      $(".show-passs .show-eye").show();
    },
    inputLabelStyle(event, type = null) {
      if (type == "parent") {
        $(event.currentTarget).parent().parent().addClass("input-border");
        $(
          event.currentTarget.parentElement.parentElement.querySelector("label")
        ).addClass("label-top");
      } else {
        $(event.currentTarget).addClass("input-border");
        $(event.currentTarget.querySelector("label")).addClass("label-top");
      }
    },
  },
  mounted() {
    this.hidePass();
    this.getRemeberCredientials();
    if (this.$route.query.message) {
      this.message = this.$route.query.message;
    }
  },
};
</script>